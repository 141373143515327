import React, { useEffect, useState } from "react";
import moment from "moment";
import warning from "./../../img/warning.png";
import "moment/locale/it";
import CodiceFiscale from "codice-fiscale-js";
moment.locale("it");

function renewExpiration(expiration, renewal) {
  const today = new Date();
  let dataExpiration = new Date(expiration); // Crea un oggetto Date dalla data di scadenza

  // Continua ad aggiungere il valore di target.rinnovo finché la data risultante non è maggiore di oggi
  while (dataExpiration <= today) {
    // Aggiungi target.rinnovo giorni alla data
    dataExpiration.setDate(dataExpiration.getDate() + renewal);
  }

  return dataExpiration;
}

function validateEmail(email, toggleEmailError, setEmailError) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let error = re.test(String(email).toLowerCase());
  if (email === "") {
    toggleEmailError(false);
    setEmailError(false);
  } else {
    toggleEmailError(!error);
    setEmailError(!error);
  }
  return error;
}

function validateTaxCode(taxCode, hasVAT, toggleTaxCodeError, setTaxCodeError) {
  let error;
  if (taxCode === "") {
    setTaxCodeError(false);
    toggleTaxCodeError(false);
  } else {
    if (!hasVAT) {
      try {
        new CodiceFiscale(taxCode);
        error = false;
      } catch {
        error = true;
      }
    } else {
      error = taxCode.length !== 11;
    }
    toggleTaxCodeError(error);
    setTaxCodeError(error);
  }
  return error;
}

function getPdf(url) {
  var a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.download = "privacypolicy.pdf";
  document.body.appendChild(a);
  a.click();
  a.remove();
}

const SecondStep = ({
  chosenCard,
  chosenTarget,
  colors,
  width,
  name,
  setName,
  surname,
  setSurname,
  email,
  setEmail,
  setEmailError,
  address,
  setAddress,
  city,
  setCity,
  county,
  setCounty,
  zip,
  setZip,
  taxCode,
  setTaxCode,
  setTaxCodeError,
  sdiCode,
  setSdiCode,
  hasVAT,
  setHasVAT,
  profilationCheckBox,
  setProfilationCheckBox,
  marketingCheckBox,
  setMarketingCheckBox,
  privacyPolicy,
  section,
  cardToRenew,
}) => {
  const [emailError, toggleEmailError] = useState(false);
  const [taxCodeError, toggleTaxCodeError] = useState(false);
  //const [showQuestion, setShowQuestion] = useState(false);
  const [useCardData, setUseCardData] = useState(false);

  useEffect(() => {
    if (useCardData) {
      setName(cardToRenew.vendita_tessere?.nome);
      setSurname(cardToRenew.vendita_tessere?.cognome);
      setEmail(cardToRenew.vendita_tessere?.email);
      setAddress(cardToRenew.vendita_tessere?.indirizzo.via);
      setCity(cardToRenew.vendita_tessere?.indirizzo.citta);
      setCounty(cardToRenew.vendita_tessere?.indirizzo.provincia);
      setZip(cardToRenew.vendita_tessere?.indirizzo.cap);
      let hasVAT = cardToRenew.vendita_tessere?.codice_fiscale ? false : true;
      setHasVAT(hasVAT);
      setTaxCode(
        hasVAT
          ? cardToRenew.vendita_tessere?.partita_iva
          : cardToRenew.vendita_tessere?.codice_fiscale
      );
      setSdiCode(hasVAT ? cardToRenew.vendita_tessere?.sdi : "");
    }
  }, [
    useCardData,
    cardToRenew,
    setName,
    setSurname,
    setEmail,
    setAddress,
    setCity,
    setCounty,
    setZip,
    setHasVAT,
    setTaxCode,
    setSdiCode,
  ]);

  const styles = {
    body: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      top: 0,
    },
    rowTop: {
      width: "90%",
      height: 100,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderBottom: "1px solid black",
      paddingTop: width > 1000 ? 0 : 30,
      paddingBottom: width > 1000 ? 0 : 30,
    },
    rowBottom: {
      display: "flex",
      flexDirection: "column",
      width: "90%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "100%",
      height: "100%",
      paddingBottom: 20,
      paddingTop: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maginTop: 20,
      marginBottom: 20,
    },
    cardImg: {
      height: "60%",
      objectFit: "contain",
      border: "1px solid darkgray",
      borderRadius: 15,
    },
    type: {
      textAlign: "left",
      marginBottom: 0,
      color: colors.darkgray,
      fontWeight: "500",
      marginTop: 10,
      fontSize: 15,
      marginRight: 5,
    },
    target: {
      textAlign: "left",
      marginBottom: 0,
      color: colors.gray,
      fontSize: 10,
      fontWeight: "500",
      marginTop: 10,
      marginRight: 5,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      width: "35%",
      alignItems: "center",
      paddingTop: 20,
      paddingBottom: 20,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    textInput: {
      outline: "none",
      border: "1px solid lightgray",
      width: 250,
      height: 20,
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      padding: 5,
      textAlign: "center",
    },
    label: {
      fontSize: 13,
      color: colors.darkgray,
    },
    selectBox: {
      marginTop: width > 1000 ? 20 : 10,
      marginBottom: width > 1000 ? 20 : 10,
    },
    description: {
      fontSize: 10,
      width: "80%",
      textAlign: "right",
      color: colors.darkgray,
      margin: 10,
    },
    text: {
      fontSize: 10,
      fontWeight: "500",
      color: colors.darkgray,
      textAlign: "left",
    },
    question: {
      fontSize: 18,
      fontWeight: "700",
      color: colors.darkgray,
      textAlign: "left",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    arrow: {
      width: 10,
      objectFit: "contain",
      //transform: showQuestion && "rotate(180deg)",
    },
    warning: {
      width: 40,
      objectFit: "contain",
      margin: 10,
      marginRight: 30,
    },
    button: {
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      fontSize: 10,
      padding: 0,
    },
  };

  const profilationSentence = () => {
    return (
      <p style={{ ...styles.description, textAlign: "left" }}>
        Presa visione dell'
        <button
          style={{ ...styles.button, color: colors.darkgray }}
          onClick={() => getPdf(privacyPolicy.url)}
        >
          <b>Informativa Privacy</b>
        </button>
        , autorizzo al trattamento da parte del Titolare dei miei Dati Personali
        per finalità di <b>profilazione</b> dei miei interessi e preferenze, per
        migliorare la conoscenza dei miei bisogni ed aspettative rispetto
        all’offerta fornita dal Titolare.
      </p>
    );
  };

  const marketingSentence = () => {
    return (
      <p style={{ ...styles.description, textAlign: "left" }}>
        Ho preso visione dell'
        <button
          style={{ ...styles.button, color: colors.darkgray }}
          onClick={() => getPdf(privacyPolicy.url)}
        >
          <b>Informativa Privacy</b>
        </button>{" "}
        riguardante il trattamento da parte del Titolare dei miei Dati Personali
        per finalità di <b>marketing</b> al fine di inviarmi newsletter e
        fornirmi informazioni su iniziative promozionali relative a prodotti ed
        eventi attraverso modalità di contatto automatizzate (i.e. posta
        elettronica)
      </p>
    );
  };
  return (
    <div style={styles.body}>
      <div style={styles.rowTop}>
        {width > 1000 && (
          <img
            style={styles.cardImg}
            src={chosenTarget?.img.url}
            alt="Tessere"
          ></img>
        )}
        <div
          style={{
            width: "60%",
            display: "flex",
            flexDirection: "row",
            justifyContent: width > 1000 ? "flex-end" : "flex-start",
            alignItems: "flex-end",
            textAlign: "left",
          }}
        >
          <p style={styles.type}>
            {section === 2
              ? cardToRenew.tipologie_tessere?.nome
              : chosenCard?.nome}
          </p>
          <p style={styles.target}>
            {section === 2
              ? cardToRenew.target_tessere?.nome
              : chosenTarget?.nome}
          </p>
        </div>
        {width > 1000 && (
          <div
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              textAlign: "left",
            }}
          >
            <p
              style={{
                ...styles.target,
                fontSize: 10,
                fontWeight: "500",
                marginTop: 10,
                marginRight: 5,
              }}
            >
              {section === 2
                ? "Nuova scadenza"
                : chosenTarget?.validita === "giorni"
                ? "Validità"
                : "Valida fino al"}
            </p>
            <p
              style={{
                ...styles.type,
                fontWeight: "500",
                marginTop: 10,
                fontSize: 15,
              }}
            >
              {section === 2
                ? moment(
                    renewExpiration(
                      cardToRenew?.scadenza,
                      cardToRenew.target_tessere?.rinnovo
                    )
                  ).format("DD MMM yyyy")
                : chosenTarget?.validita === "giorni"
                ? chosenTarget?.giorni + " giorni"
                : moment(new Date(chosenTarget?.data_scadenza)).format(
                    "DD MMM yyyy"
                  )}
            </p>
          </div>
        )}
        <div
          style={{
            width: "60%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            textAlign: "left",
          }}
        >
          <p
            style={{
              ...styles.target,
              fontSize: 10,
              fontWeight: "500",
              marginTop: 10,
              marginRight: 5,
            }}
          >
            Prezzo
          </p>
          <p
            style={{
              ...styles.type,
              fontWeight: "500",
              marginTop: 10,
              fontSize: 15,
            }}
          >
            {section === 2
              ? cardToRenew.target_tessere?.prezzo.toFixed(2)
              : chosenTarget?.prezzo.toFixed(2)}
          </p>
        </div>
      </div>

      {/*Desktop mode*/}
      {width > 1000 ? (
        <div style={styles.rowBottom}>
          <div style={styles.subcontainer}>
            {/* {!section === 2 && (
              <div
                style={{
                  width: "55%",
                  cursor: "pointer",
                  padding: 15,
                  marginTop: 40,
                  backgroundColor: colors.verylightgray,
                }}
                onClick={() => setShowQuestion(!showQuestion)}
              >
                <p style={styles.question}>
                  Vuoi regalare la card?{" "}
                  <img
                    style={styles.arrow}
                    src={arrow}
                    alt="Open question"
                  ></img>
                </p>
                {showQuestion && (
                  <p style={styles.text}>
                    Compila i campi con le <u>tue</u> informazioni di
                    fatturazione.
                    <br />
                    La carta verrà abbinata al destinatario solo al momento
                    dell'attivazione. <br />
                    Per attivare la card, occorre recarsi in biglietteria con il
                    voucher che verrà emesso al termine dell'acquisto. Ricorda
                    che <b>solo</b> al momento dell'attivazione ti verranno
                    richiesti nome, cognome, email e data di nascita della/e
                    persona/e a cui vuoi intestare la card acquistata.
                  </p>
                )}
              </div>
            )} */}
            {section === 2 &&
              cardToRenew.gruppo_tessere?.tesseres.length > 0 && (
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(235, 174, 52, 0.5)",
                    padding: 20,
                    marginBottom: 20,
                  }}
                >
                  <p
                    style={{
                      ...styles.description,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Rinnovando questa tessera verr
                    {cardToRenew.gruppo_tessere.tesseres.length > 2
                      ? "anno"
                      : "à"}{" "}
                    automaticamente rinnovat
                    {cardToRenew.gruppo_tessere.tesseres.length > 2
                      ? "e"
                      : "a"}{" "}
                    anche l
                    {cardToRenew.gruppo_tessere.tesseres.length > 2 ? "e" : "a"}{" "}
                    tesser
                    {cardToRenew.gruppo_tessere.tesseres.length > 2
                      ? "e"
                      : "a"}{" "}
                    di
                  </p>
                  <ul
                    style={{
                      listStyle: "none",
                      padding: 0,
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {cardToRenew.gruppo_tessere.tesseres
                      .filter((ca) => ca.numero !== cardToRenew.numero)
                      .map((c, key) => {
                        return (
                          <li
                            key={key}
                            style={{
                              ...styles.type,
                              fontWeight: "500",
                              marginTop: 10,
                              fontSize: 13,
                              textAlign: "left",
                            }}
                          >
                            {c.nome} {c.cognome} - {c.numero}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
            <div
              style={{
                width: "90%",
                cursor: "pointer",
                margin: 0,
              }}
            >
              <p style={styles.question}>
                Inserisci i tuoi dati di fatturazione
              </p>
              {section !== 2 && section === 1 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: section === 1 && colors.verylightgray,
                    padding: 15,
                  }}
                >
                  <img
                    style={styles.warning}
                    src={warning}
                    alt="Warning icon"
                  ></img>
                  <div style={{ marginLeft: 20 }}>
                    <p style={styles.text}>
                      Compila i campi con le <b>tue</b> informazioni di
                      fatturazione.
                      <br />
                      Al termine dell'acquisto, riceverai un'e-mail con allegato
                      il voucher regalo, che potrai consegnare al destinatario
                      della tua sorpresa. <br />
                      Il ricevente non dovrà fare altro che recarsi in
                      biglietteria con il voucher regalo e mostrare il codice
                      contenuto agli operatori di biglietteria, i quali gli
                      consegneranno, infine, la card. <br />
                      <b>La stampante non serve!</b> Il voucher è accettato sia
                      in formato cartaceo che digitale.
                    </p>
                  </div>
                </div>
              )}
            </div>
            {section === 2 && (
              <div
                style={{
                  ...styles.row,
                }}
              >
                <input
                  type="checkbox"
                  defaultChecked={useCardData}
                  onChange={(event) => setUseCardData(event.target.checked)}
                />
                <p style={styles.text}>
                  Utilizza i dati di fatturazione forniti al momento
                  dell'acquisto
                </p>
              </div>
            )}
            <div style={styles.row}>
              <div style={styles.column}>
                <div style={styles.selectBox}>
                  <p style={styles.label}>Nome</p>
                  <input
                    type="text"
                    style={styles.textInput}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    placeholder="Nome"
                  ></input>
                </div>
                <div style={styles.selectBox}>
                  <p style={styles.label}>Indirizzo</p>
                  <input
                    type="text"
                    style={styles.textInput}
                    value={address}
                    onChange={(event) => {
                      setAddress(event.target.value);
                    }}
                    placeholder="Indirizzo"
                  ></input>
                </div>
                <div style={styles.selectBox}>
                  <p style={styles.label}>CAP</p>
                  <input
                    type="text"
                    style={styles.textInput}
                    value={zip}
                    onChange={(event) => {
                      setZip(event.target.value.replace(/[^\d]/, ""));
                    }}
                    placeholder="CAP"
                  ></input>
                </div>
              </div>
              <div style={styles.column}>
                <div style={styles.selectBox}>
                  <p style={styles.label}>Cognome</p>
                  <input
                    type="text"
                    style={styles.textInput}
                    value={surname}
                    onChange={(event) => setSurname(event.target.value)}
                    placeholder="Cognome"
                  ></input>
                </div>
                <div style={styles.selectBox}>
                  <p style={styles.label}>Città</p>
                  <input
                    type="text"
                    style={styles.textInput}
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                    placeholder="Città"
                  ></input>
                </div>
                <div style={styles.selectBox}>
                  <p style={styles.label}>
                    {hasVAT ? "Partita IVA" : "Codice fiscale"}
                  </p>
                  <input
                    type="text"
                    style={styles.textInput}
                    value={taxCode}
                    onChange={(event) => {
                      if (event.target.value.length <= (hasVAT ? 11 : 16)) {
                        setTaxCode(event.target.value.toUpperCase());
                        validateTaxCode(
                          event.target.value,
                          hasVAT,
                          toggleTaxCodeError,
                          setTaxCodeError
                        );
                      }
                    }}
                    placeholder={hasVAT ? "Partita IVA" : "Codice fiscale"}
                  ></input>
                  {taxCodeError && (
                    <p
                      style={{
                        color: colors.highlight,
                        marginTop: 2,
                        fontSize: 10,
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {hasVAT
                        ? "La Partita IVA non valida"
                        : "Codice fiscale errato"}
                    </p>
                  )}
                </div>
              </div>
              <div style={styles.column}>
                {/*<div style={styles.selectBox}>
                  <p style={styles.label}>Data di nascita</p>
                  <input
                    type="date"
                    name="birth"
                    max={moment(new Date()).format("yyyy-MM-DD")}
                    value={moment(birth).format("yyyy-MM-DD")}
                    onChange={(event) => setBirth(event.target.value)}
                    placeholder="Data di nascita"
                    style={styles.textInput}
                  />
                  </div>*/}
                <div style={styles.selectBox}>
                  <p style={styles.label}>Email</p>
                  <input
                    type="text"
                    style={styles.textInput}
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                      validateEmail(
                        event.target.value,
                        toggleEmailError,
                        setEmailError
                      );
                    }}
                    placeholder="Email"
                  ></input>
                  {emailError && (
                    <p
                      style={{
                        color: colors.highlight,
                        marginTop: 2,
                        fontSize: 10,
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Email non valida
                    </p>
                  )}
                </div>
                <div style={styles.selectBox}>
                  <p style={styles.label}>Provincia</p>
                  <input
                    type="text"
                    style={styles.textInput}
                    value={county}
                    onChange={(event) => {
                      if (event.target.value.length <= 2) {
                        setCounty(event.target.value.toUpperCase());
                      }
                    }}
                    placeholder="Provincia"
                  ></input>
                </div>
                {hasVAT ? (
                  <div style={styles.selectBox}>
                    <p style={styles.label}>SDI</p>
                    <input
                      type="text"
                      style={styles.textInput}
                      value={sdiCode}
                      onChange={(event) => {
                        if (event.target.value.length <= 7) {
                          setSdiCode(event.target.value.toUpperCase());
                        }
                      }}
                      placeholder="SDI"
                    ></input>
                  </div>
                ) : (
                  <div style={styles.selectBox}>
                    {" "}
                    <div
                      style={{
                        ...styles.row,
                        marginTop: 40,
                      }}
                    >
                      <input
                        type="checkbox"
                        defaultChecked={hasVAT}
                        onChange={(event) => setHasVAT(event.target.checked)}
                      />
                      <p style={styles.text}>Ho la partita IVA</p>
                    </div>
                  </div>
                )}
              </div>
            </div>{" "}
            {hasVAT && (
              <div
                style={{
                  ...styles.row,
                }}
              >
                <input
                  type="checkbox"
                  defaultChecked={hasVAT}
                  onChange={(event) => setHasVAT(event.target.checked)}
                />
                <p style={styles.text}>Ho la partita IVA</p>
              </div>
            )}
            {section !== 2 && (
              <div
                style={{
                  ...styles.row,
                  marginTop: 40,
                }}
              >
                <input
                  type="checkbox"
                  defaultChecked={profilationCheckBox}
                  onChange={(event) =>
                    setProfilationCheckBox(event.target.checked)
                  }
                />
                {profilationSentence()}
              </div>
            )}
            {section !== 2 && (
              <div style={styles.row}>
                <input
                  type="checkbox"
                  defaultChecked={marketingCheckBox}
                  onChange={(event) =>
                    setMarketingCheckBox(event.target.checked)
                  }
                />
                {marketingSentence()}
              </div>
            )}
          </div>
        </div>
      ) : (
        /*Mobile & tablet mode*/
        <div style={styles.rowBottom}>
          {section === 2 && cardToRenew.gruppo_tessere?.tesseres.length > 0 && (
            <div
              style={{
                backgroundColor: "rgb(235, 174, 52, 0.5)",
                padding: 20,
                marginTop: 20,
              }}
            >
              <p
                style={{
                  ...styles.description,
                  width: "auto",
                  textAlign: "center",
                }}
              >
                Rinnovando questa tessera verr
                {cardToRenew.gruppo_tessere.tesseres.length > 2
                  ? "anno"
                  : "à"}{" "}
                automaticamente rinnovat
                {cardToRenew.gruppo_tessere.tesseres.length > 2
                  ? "e"
                  : "a"}{" "}
                anche l
                {cardToRenew.gruppo_tessere.tesseres.length > 2 ? "e" : "a"}{" "}
                tesser
                {cardToRenew.gruppo_tessere.tesseres.length > 2 ? "e" : "a"} di
              </p>
              <ul
                style={{
                  listStyle: "none",
                  display: "flex",
                  padding: 0,
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {cardToRenew.gruppo_tessere.tesseres
                  .filter((ca) => ca.numero !== cardToRenew.numero)
                  .map((c, key) => {
                    return (
                      <li
                        key={key}
                        style={{
                          ...styles.type,
                          fontWeight: "500",
                          marginTop: 10,
                          fontSize: 13,
                          textAlign: "center",
                        }}
                      >
                        {c.nome} {c.cognome} - {c.numero}
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
          <div
            style={{
              ...styles.column,
              width: "100%",
              height: "100%",
            }}
          >
            {/* {section !== 2 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: 25,
                  marginBottom: 40,
                }}
                onClick={() => setShowQuestion(!showQuestion)}
              >
                <p style={{ ...styles.question, width: "70%" }}>
                  Vuoi regalare la card?{" "}
                  <img
                    style={styles.arrow}
                    src={arrow}
                    alt="Open question"
                  ></img>
                </p>
                {showQuestion && (
                  <p style={{ ...styles.text, width: "70%" }}>
                    Compila i campi sottostanti con le <u>tue</u> informazioni
                    personali.
                    <br />
                    La carta verrà abbinata al suo intestatario solo al momento
                    dell'attivazione. <br />
                    Per attivare la card, occorre recarsi in biglietteria con il
                    voucher che verrà emesso al termine dell'acquisto. Ricorda
                    che al momento dell'attivazione ti verranno richiesti nome,
                    cognome, email e data di nascita della/e persona/e a cui
                    vuoi intestare la card acquistata.
                  </p>
                )}
              </div>
            )} */}

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                {section === 1 && (
                  <img
                    style={styles.warning}
                    src={warning}
                    alt="Warning icon"
                  ></img>
                )}
                <div>
                  <p style={styles.question}>
                    Inserisci i tuoi dati di fatturazione
                  </p>
                  {section !== 2 && section === 1 && (
                    <p style={styles.text}>
                      Compila i campi con le <b>tue</b> informazioni di
                      fatturazione.
                      <br />
                      Al termine dell'acquisto, riceverai un'e-mail con allegato
                      il voucher regalo, che potrai consegnare al destinatario
                      della tua sorpresa. <br />
                      Il ricevente non dovrà fare altro che recarsi in
                      biglietteria con il voucher regalo e mostrare il codice
                      agli operatori di biglietteria. <br />
                      <b>La stampante non serve!</b> Il voucher è accettato sia
                      in formato cartaceo che digitale.
                    </p>
                  )}
                </div>
              </div>
            </div>

            {section === 2 && (
              <div
                style={{
                  ...styles.row,
                }}
              >
                <input
                  type="checkbox"
                  defaultChecked={useCardData}
                  onChange={(event) => setUseCardData(event.target.checked)}
                />
                <p style={styles.text}>
                  Utilizza i dati di fatturazione forniti al momento
                  dell'acquisto
                </p>
              </div>
            )}
            <div style={styles.selectBox}>
              <p style={styles.label}>Nome</p>
              <input
                type="text"
                style={styles.textInput}
                value={name}
                onChange={(event) => setName(event.target.value)}
                placeholder="Nome"
              ></input>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>Cognome</p>
              <input
                type="text"
                style={styles.textInput}
                value={surname}
                onChange={(event) => setSurname(event.target.value)}
                placeholder="Cognome"
              ></input>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>Email</p>
              <input
                type="text"
                style={styles.textInput}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                  validateEmail(
                    event.target.value,
                    toggleEmailError,
                    setEmailError
                  );
                }}
                placeholder="Email"
              ></input>
              {emailError && (
                <p
                  style={{
                    color: colors.highlight,
                    marginTop: 2,
                    fontSize: 10,
                    width: "100%",
                  }}
                >
                  Email non valida
                </p>
              )}
            </div>
            {/*<div style={styles.selectBox}>
              <p style={styles.label}>Data di nascita</p>
              <input
                type="date"
                name="birth"
                max={moment(new Date()).format("yyyy-MM-DD")}
                value={moment(birth).format("yyyy-MM-DD")}
                onChange={(event) => setBirth(event.target.value)}
                placeholder="Data di nascita"
                style={styles.textInput}
              />
                </div>*/}
            <div style={styles.selectBox}>
              <p style={styles.label}>Indirizzo</p>
              <input
                type="text"
                style={styles.textInput}
                value={address}
                onChange={(event) => {
                  setAddress(event.target.value);
                }}
                placeholder="Indirizzo"
              ></input>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>Città</p>
              <input
                type="text"
                style={styles.textInput}
                value={city}
                onChange={(event) => setCity(event.target.value)}
                placeholder="Città"
              ></input>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>Provincia</p>
              <input
                type="text"
                style={styles.textInput}
                value={county}
                onChange={(event) => {
                  if (event.target.value.length <= 2) {
                    setCounty(event.target.value.toUpperCase());
                  }
                }}
                placeholder="Provincia"
              ></input>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>CAP</p>
              <input
                type="text"
                style={styles.textInput}
                value={zip}
                onChange={(event) => {
                  setZip(event.target.value.replace(/[^\d]/, ""));
                }}
                placeholder="CAP"
              ></input>
            </div>
            <div
              style={{
                ...styles.row,
              }}
            >
              <input
                type="checkbox"
                defaultChecked={hasVAT}
                onChange={(event) => setHasVAT(event.target.checked)}
              />
              <p style={styles.text}>Ho la partita IVA</p>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>
                {hasVAT ? "Partita IVA" : "Codice fiscale"}
              </p>
              <input
                type="text"
                style={styles.textInput}
                value={taxCode}
                onChange={(event) => {
                  if (event.target.value.length <= (hasVAT ? 11 : 16)) {
                    setTaxCode(event.target.value.toUpperCase());
                    validateTaxCode(
                      event.target.value,
                      hasVAT,
                      toggleTaxCodeError,
                      setTaxCodeError
                    );
                  }
                }}
                placeholder={hasVAT ? "Partita IVA" : "Codice fiscale"}
              ></input>
              {taxCodeError && (
                <p
                  style={{
                    color: colors.highlight,
                    marginTop: 2,
                    fontSize: 10,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {hasVAT ? "Partita IVA non valida" : "Codice fiscale errato"}
                </p>
              )}
            </div>
            {hasVAT && (
              <div style={styles.selectBox}>
                <p style={styles.label}>SDI</p>
                <input
                  type="text"
                  style={styles.textInput}
                  value={sdiCode}
                  onChange={(event) => {
                    if (event.target.value.length <= 7) {
                      setSdiCode(event.target.value.toUpperCase());
                    }
                  }}
                  placeholder="SDI"
                ></input>
              </div>
            )}

            {section !== 2 && (
              <div style={{ ...styles.row, marginTop: 20 }}>
                <input
                  type="checkbox"
                  defaultChecked={profilationCheckBox}
                  onChange={(event) =>
                    setProfilationCheckBox(event.target.checked)
                  }
                />
                {profilationSentence()}
              </div>
            )}
            {section !== 2 && (
              <div style={styles.row}>
                <input
                  type="checkbox"
                  defaultChecked={marketingCheckBox}
                  onChange={(event) =>
                    setMarketingCheckBox(event.target.checked)
                  }
                />
                {marketingSentence()}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SecondStep;
