import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import history from "./services/history";
import Routes from "./routes";
import {
  UserContextProvider,
  OrgContextProvider,
  CheckoutContextProvider,
} from "./contexts";

const RedirectToNonWWW = () => {
  useEffect(() => {
    const shouldRedirect = window.location.hostname.startsWith("www.");
    if (shouldRedirect) {
      const newUrl = window.location.href.replace("www.", "");
      window.location.replace(newUrl);
    }
  }, []);

  return null;
};

function App() {
  // // Receive messages from parent window (iframe)
  // window.addEventListener("message", function (event) {
  //   const message = event.data;

  //   // Check message type
  //   if (message.type === "TOKEN") {
  //     const token = message.data;
  //     // Do something with the token
  //   }
  // });

  return (
    <div className="App">
      <Router>
        <Route path="/*" exact component={RedirectToNonWWW} />
        <UserContextProvider>
          <OrgContextProvider>
            <CheckoutContextProvider>
              <Router history={history}>
                <Routes />
              </Router>
            </CheckoutContextProvider>
          </OrgContextProvider>
        </UserContextProvider>
      </Router>
    </div>
  );
}

export default App;
